<template>
  <div>
    <b-modal ref="modal-rule" id="modal-rule" centered size="lg" title="" hide-footer>
      <b-tabs align="center" style="width:max-content;display:inline;" justified>
        <b-tab active title="베팅" class="rule-tab">
          <table class="w-100 mt-3 bet-info-table">
            <tr>
              <th class="text-left">단폴더베팅</th>
              <td class="text-right text-success" v-if="rullData.use_danpol == 1">가능</td>
              <td class="text-right text-danger" v-else>불가능</td>
            </tr>
            <tr>
              <th class="text-left">단폴더 최대베팅금</th>
              <td class="text-right text-info">{{ getFormatNumber(rullData.max_bet_danpol) }}</td>
            </tr>
            <tr>
              <th class="text-left">단폴더 최대적중금</th>
              <td class="text-right text-info">{{ getFormatNumber(rullData.max_prize_danpol) }}</td>
            </tr>
            <tr>
              <th class="text-left w-50">최대조합수</th>
              <td class="text-right w-50 text-success">{{ getFormatNumber(rullData.max_folder) }}</td>
            </tr>
            <tr>
              <th class="text-left w-50">최소베팅금</th>
              <td class="text-right w-50 text-info">{{ getFormatNumber(rullData.min_bet) }}</td>
            </tr>
            <tr>
              <th class="text-left w-50">조합 최대베팅금</th>
              <td class="text-right w-50 text-info">{{ getFormatNumber(rullData.max_bet) }}</td>
            </tr>
            <tr>
              <th class="text-left w-50">조합 최대적중금</th>
              <td class="text-right w-50 text-info">{{ getFormatNumber(rullData.max_prize) }}</td>
            </tr>
            <tr>
              <th class="text-left w-50">최대배당률</th>
              <td class="text-right w-50 text-info">{{ getFormatNumber(rullData.max_rate) }}</td>
            </tr>
          </table>
        </b-tab>

        <b-tab title="연장전" class="rule-tab">
          <table class="w-100 mt-3 bet-info-table">
            <tr>
              <th class="text-cetter">구분</th>
              <th class="text-cetter">⚽</th>
              <th class="text-cetter">⚾</th>
              <th class="text-cetter">🏀</th>
              <th class="text-cetter">🏐</th>
              <th class="text-cetter">🏒</th>
            </tr>
            <tr>
              <td class="text-cetter">승무패</td>
              <td class="text-cetter text-warning">X</td>
              <td class="text-cetter text-success">-</td>
              <td class="text-cetter text-success">-</td>
              <td class="text-cetter text-warning">-</td>
              <td class="text-cetter text-warning">X</td>
            </tr>
            <tr>
              <td class="text-cetter">승패</td>
              <td class="text-cetter text-warning">-</td>
              <td class="text-cetter text-success">O</td>
              <td class="text-cetter text-success">O</td>
              <td class="text-cetter text-warning">X</td>
              <td class="text-cetter text-warning">O</td>
            </tr>
            <tr>
              <td class="text-cetter">핸디캡</td>
              <td class="text-cetter text-warning">X</td>
              <td class="text-cetter text-success">O</td>
              <td class="text-cetter text-success">O</td>
              <td class="text-cetter text-warning">X</td>
              <td class="text-cetter text-warning">O</td>
            </tr>
            <tr>
              <td class="text-cetter">오버언더</td>
              <td class="text-cetter text-warning">X</td>
              <td class="text-cetter text-success">O</td>
              <td class="text-cetter text-success">O</td>
              <td class="text-cetter text-warning">X</td>
              <td class="text-cetter text-warning">O</td>
            </tr>
          </table>
        </b-tab>

        <b-tab title="보너스" class="rule-tab">
          <table class="w-100 mt-3 bet-info-table">
            <tr>
              <th class="text-left w-50">보너스 적용배당</th>
              <td class="text-right w-50 text-success">{{ getFormatNumber(rullData.min_rate_for_bonus) }} 이상</td>
            </tr>
            <tr>
              <th class="text-left w-50">{{ rullData.min_folder_for_bonus1 }} 폴더 이상</th>
              <td class="text-right w-50 text-info">{{ getFormatNumber(rullData.bonus_rate1) }}</td>
            </tr>
            <tr>
              <th class="text-left w-50">{{ rullData.min_folder_for_bonus2 }} 폴더 이상</th>
              <td class="text-right w-50 text-info">{{ getFormatNumber(rullData.bonus_rate2) }}</td>
            </tr>
            <tr>
              <th class="text-left w-50">{{ rullData.min_folder_for_bonus3 }} 폴더 이상</th>
              <td class="text-right w-50 text-info">{{ getFormatNumber(rullData.bonus_rate3) }}</td>
            </tr>
          </table>
        </b-tab>

        <b-tab title="조합" class="rule-tab">
          <table class="w-100 mt-3 bet-info-table">
            <tr>
              <th class="text-center bg-black" rowspan="2">⚽</th>
              <th class="text-left w-50">승무패+오버언더</th>
              <td class="text-center text-success" v-if="rullData.mix_soccer_smp_ou == 1">가능</td>
              <td class="text-center text-danger" v-else>불가능</td>
            </tr>
            <tr>
              <th class="text-left">핸디캡+오버언더</th>
              <td class="text-center text-success" v-if="rullData.mix_soccer_hd_ou == 1">가능</td>
              <td class="text-center text-danger" v-else>불가능</td>
            </tr>

            <tr>
              <th class="text-center bg-black" rowspan="3">⚾</th>
              <th class="text-left">승패+오버언더</th>
              <td class="text-center text-success" v-if="rullData.mix_baseball_smp_ou == 1">가능</td>
              <td class="text-center text-danger" v-else>불가능</td>
            </tr>
            <tr>
              <th class="text-left">핸디캡+오버언더</th>
              <td class="text-center text-success" v-if="rullData.mix_baseball_hd_ou == 1">가능</td>
              <td class="text-center text-danger" v-else>불가능</td>
            </tr>
            <tr>
              <th class="text-left">핸디캡+오버언더(5이닝)</th>
              <td class="text-center text-success" v-if="rullData.mix_baseball_sp == 1">가능</td>
              <td class="text-center text-danger" v-else>불가능</td>
            </tr>

            <tr>
              <th class="text-center bg-black" rowspan="3">🏀</th>
              <th class="text-left">승패+오버언더</th>
              <td class="text-center text-success" v-if="rullData.mix_basket_smp_ou == 1">가능</td>
              <td class="text-center text-danger" v-else>불가능</td>
            </tr>
            <tr>
              <th class="text-left">핸디캡+오버언더</th>
              <td class="text-center text-success" v-if="rullData.mix_basket_hd_ou == 1">가능</td>
              <td class="text-center text-danger" v-else>불가능</td>
            </tr>
            <tr>
              <th class="text-left">핸디캡+오버언더(1쿼터)</th>
              <td class="text-center text-success" v-if="rullData.mix_basket_sp == 1">가능</td>
              <td class="text-center text-danger" v-else>불가능</td>
            </tr>
            
          </table>
        </b-tab>

        <b-tab title="기타" class="rule-tab"></b-tab>
      </b-tabs>
      
    </b-modal>

    <div style="color:#ffe588;cursor:pointer;float:left;" id="show-btn" @click="showRule">
      <feather-icon size="16" icon="HelpCircleIcon" />
      <span class="ml-h" style="font-family:Noto Sans KR, sans-serif !important;" >게임규정</span>
    </div>
  </div>
</template>

<script>
import { BNavItem, BModal, BTable, BCard, BRow, BCol, BTab, BTabs } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
    BModal,
    BTable,
    BCard,
    BRow,
    BCol,
    BTab, BTabs
  },
  data() {
    return {
      rullData:[],
    }
  },
  methods: {
    async showRule(){
      await this.$api('http://server.honey-game.com/games/rule', 'post', {
        at: localStorage.getItem("accessToken"),
        rt: localStorage.getItem("refreshToken"),
      }).then(datas =>{
        if( datas.result == 0 ){
          this.$swal({
            icon: 'error',
            title: '오류가 발생하였습니다',
            text: datas.msg,
            customClass: {
              confirmButton: 'btn btn-error',
            },
          });
          return;
        }
        this.rullData = datas.rule;
        this.$refs['modal-rule'].show();
      })
    },
    getFormatNumber(num){
      if(num)
      {
        var regexp = /\B(?=(\d{3})+(?!\d))/g;
        return num.toString().replace(regexp, ',');
      }
    },
  }
}
</script>

<style>
#modal-rule .modal-lg {max-width:500px !important;}
#table-rule td {padding:0.5rem !important;}

.bet-info-table .bg-black { background-color:#070d1b !important;border-right: 2px solid #3b4253; width:100px;}
.bet-info-table th {padding:10px 20px; background-color:#343d55;border-bottom: 2px solid #3b4253;color: #d0d2d6;}
.bet-info-table td {padding:10px 20px; color: #d0d2d6; background-color:#202940;}
.rule-tab {min-height:350px;}
</style>