import { render, staticRenderFns } from "./GameLeft3.vue?vue&type=template&id=15f9b863&"
import script from "./GameLeft3.vue?vue&type=script&lang=js&"
export * from "./GameLeft3.vue?vue&type=script&lang=js&"
import style0 from "./GameLeft3.vue?vue&type=style&index=0&id=15f9b863&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports