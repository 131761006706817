<template>
  <div>
    <!-- 로그인 전 -->
    <b-row v-if="users.length == 0">
      <b-col cols="12">
        <b-button v-b-modal.modal-login v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="shadow w-100">
          <span>로그인 </span>
          <feather-icon icon="ChevronsRightIcon" />
        </b-button>
      </b-col>
      <b-col cols="12" class="mt-h">
        <b-button type="button" variant="outline-primary" class="shadow w-100">
          회원가입
        </b-button>
      </b-col>
    </b-row>

    <!-- 로그인 후 -->
    <b-row v-else>
      <b-col>[ {{ users.mem_id }} ]님 환영합니다</b-col>
      <b-col>
        <b-button id="show-btn" @click="showBetList">베팅내역</b-button>
      </b-col>
    </b-row>

    <!-- 로그인 창 -->
    <b-modal ref="modal-login" id="modal-login" cancel-variant="outline-secondary" ok-title="Login" cancel-title="Close" centered
      title="Login" hide-footer>
      <b-form @submit="onSubmit" @reset="onReset" class="">
        <b-form-group>
          <label for="mem_id">ID : </label>
          <b-form-input name="mem_id" id="mem_id" type="text" placeholder="ID" v-model="form.mem_id"/>
        </b-form-group>
        <b-form-group>
          <label for="mem_pass">PASS : </label>
          <b-form-input name="mem_pass" id="mem_pass" type="password" placeholder="Password" v-model="form.mem_pass"/>
        </b-form-group>

        <b-form-group class="text-center">
        <b-button type="submit" variant="primary">로그인</b-button>
        <b-button type="reset" variant="danger" class="ml-h">취소</b-button>
        </b-form-group>
      </b-form>
    </b-modal>

    <!-- 베팅내역 창 -->
    <b-modal
      ref="modal-bet-list"
      id="modal-bet-list"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      title="베팅내역"
    >
      <div :key="i" v-for="(item, i) in betList">
        <b-row>
          <b-col>{{ item.regdate }}</b-col>
          <b-col>{{ item.total_odds }}</b-col>
          <b-col>{{ item.betting_count }}</b-col>
          <b-col>{{ item.betting_money }}</b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
  
</template>

<script>
import { BButton, BRow, BCol, BModal, BForm, BFormInput, BFormGroup } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'

export default {
  data() {
    return {
      form: {
        mem_id: '',
        mem_pass: '',
      },
      users: [],
      betList: []
    }
  },
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
  },
  create() {
  },
  mounted(){
    this.users = getUserData();
  },
  directives: {
    Ripple,
  },
  methods: {
    async showBetList(){
      await this.$api('http://server.honey-game.com/games/bet_list', 'post', {
        at: localStorage.getItem("accessToken"),
        rt: localStorage.getItem("refreshToken")
      }).then(datas =>{
        this.betList = datas.betList;
        this.$refs['modal-bet-list'].show();
      })
    },
    onSubmit(event) {
      event.preventDefault();
    },
    onReset(event){
      this.$refs['modal-login'].hide();
    },
    Logout()
    {
      this.$swal({
        text: "로그아웃 하시겠습니까",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '확인',
        cancelButtonText: '취소',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$cookies.remove("access_token");
          this.$cookies.remove("refresh_token");
          this.users = null;

          this.$swal({
            icon: 'success',
            title: '로그아웃 성공',
            text: '정상적으로 처리되었습니다',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    }
  },
}
</script>